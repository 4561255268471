@import url(_font.scss);

* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  height: 100vh;
  min-height: 100%;
  overflow: auto;
}

.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
}

.circle-icon:nth-child(1) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    grid-area: 1 / 1 / 2 / 2;
  }
}

.circle-icon:nth-child(2) {
  top: 84%;
  left: 15%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    position: relative;

    top: 0;
    left: 0;
    transform: none;
    grid-area: 1 / 2 / 2 / 3;
  }
}

.circle-icon:nth-child(3) {
  top: 96%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    position: relative;

    top: 0;
    left: 0;
    transform: none;
    grid-area: 2 / 1 / 3 / 2;
  }
}

.circle-icon:nth-child(4) {
  top: 84%;
  left: 85%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    position: relative;

    top: 0;
    left: 0;
    transform: none;
    grid-area: 2 / 2 / 3 / 3;
  }
}

.circle-icon:nth-child(5) {
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    position: relative;

    top: 0;
    left: 0;
    transform: none;
    grid-area: 3 / 1 / 4 / 2;
  }
}

.circle-icon:nth-child(6) {
  top: 15%;
  left: 85%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    grid-area: 3 / 2 / 4 / 3;
  }
}

.circle-icon:nth-child(7) {
  top: 3%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    grid-area: 4 / 1 / 5 / 2;
  }
}

.circle-icon:nth-child(8) {
  top: 15%;
  left: 15%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    grid-area: 4 / 2 / 5 / 3;
  }
}
